export const favoriteStations = [
    {
        "name": "Hello FM 106.4 - Tamil (Chennai)",
        "url": "https://tamil.crabdance.com:8002/3",
        "url_resolved": "https://tamil.crabdance.com:8002/3",
        "homepage": "https://www.hello.fm/",
        "favicon": "",
        "tags": "",
        "country": "India",
        "countrycode": "IN",
        "iso_3166_2": null,
        "state": "chennai",
        "language": "tamil",
    },
    {
    
        "name": "CKIS \"KISS 92.5\" Toronto, ON",
        "url": "https://rogers-hls.leanstream.co/rogers/tor925.stream/playlist.m3u8",
        "url_resolved": "https://rogers-hls.leanstream.co/rogers/tor925.stream/playlist.m3u8",
        "homepage": "https://www.kiss925.com/",
        "favicon": "https://upload.wikimedia.org/wikipedia/en/thumb/7/7a/KiSS_92.5_Logo.png/150px-KiSS_92.5_Logo.png",
        "tags": "contemporary hits,rogers media,top 40",
        "country": "Canada",
        "countrycode": "CA",
        "iso_3166_2": null,
        "state": "Ontario",
        "language": "english",
    },
    {

        "name": "Gulshan Radio",
        "url": "http://s6.voscast.com:11324/;",
        "url_resolved": "http://s6.voscast.com:11324/;",
        "homepage": "http://www.gulshanradio.co.uk/",
        "favicon": "",
        "tags": "music",
        "country": "The United Kingdom",
        "countrycode": "GB",
        "iso_3166_2": null,
        "state": "",
        "language": "punjabi",
    },
    {
        "name": "Radio Mirchi",
        "url": "http://peridot.streamguys.com:7150/Mirchi",
        "url_resolved": "http://peridot.streamguys.com:7150/Mirchi",
        "homepage": "http://www.radiomirchi.com/",
        "favicon": "",
        "tags": "",
        "country": "India",
        "countrycode": "IN",
        "iso_3166_2": null,
        "state": "",
        "language": "hindi",
    },
    {
        "name": "Radio mango",
        "url": "https://bcovlive-a.akamaihd.net/19b535b7499a4719a5c19e043063f5d9/ap-southeast-1/6034685947001/playlist.m3u8",
        "url_resolved": "https://bcovlive-a.akamaihd.net/19b535b7499a4719a5c19e043063f5d9/ap-southeast-1/6034685947001/playlist.m3u8",
        "homepage": "https://www.radiomango.fm/home.html/",
        "favicon": "",
        "tags": "",
        "country": "",
        "countrycode": "",
        "iso_3166_2": null,
        "state": "",
        "language": "malayalam",
    },
    {
        "name": "Suryan fm 93.5 Chennai",
        "url": "https://tamil.crabdance.com:8002/2",
        "url_resolved": "https://tamil.crabdance.com:8002/2",
        "homepage": "https://www.suryanfm.in/",
        "favicon": "",
        "tags": "",
        "country": "India",
        "countrycode": "IN",
        "iso_3166_2": null,
        "state": "Tamil Nadu",
        "language": "Tamil",
    }
];
