// mixpanel.js
import mixpanel from 'mixpanel-browser';

mixpanel.init("b4b82ace1592242df13bfb5a6316db26");

export const trackEvent = (event, properties) => {
    // console.log("Track event called")
  mixpanel.track(event, properties);
};

export default mixpanel;
