import {
    Card,
    CardMedia,
    Divider,
    Stack,
    Typography,
    IconButton,
} from "@mui/material";

import PlayIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import PauseIcon from '@mui/icons-material/PauseCircleRounded';

const SubPlayer = ({ paused, setPaused, name, state, language, country, favicon }) => {
    return (
        <div
            style={{
                width: "100vw",
                position: "absolute",
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Card sx={{ width: "100%", padding: "1rem", borderTopLeftRadius: "25px", borderTopRightRadius: "25px" }} variant="outlined">
                <Stack direction="row" spacing={3} alignItems="center" justifyContent="center" justifyItems="stretch">
                    <CardMedia
                        component="img"
                        height="100"
                        alt="Not Found"
                        src={favicon || "https://images.pexels.com/photos/3783471/pexels-photo-3783471.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"}
                        sx={{
                            width: { xs: "4rem", sm: "4rem" },
                            height: "4rem",
                            borderRadius: '25px',
                        }}
                    />

                    <Stack direction="column" spacing={0.5} alignItems="center">
                        <Typography 
                                  component="div"
                                  variant="caption"
                                  color="text.secondary"
                                  fontWeight="regular"
                                  fontSize=".6rem" >
                         🔴 LIVE
                        </Typography>
                        <Typography color="text.primary" fontWeight="medium" fontSize={15}>
                            {name || "FM Station"}
                        </Typography>
                        {/* <Typography
                            component="div"
                            variant="caption"
                            color="text.secondary"
                            fontWeight="regular"
                        >
                            Location: {state}, {country}
                        </Typography> */}
                        <Typography
                            component="div"
                            variant="caption"
                            color="text.secondary"
                            fontWeight="regular"
                        >
                            {language}
                        </Typography>
                    </Stack>

                    {/* <Divider variant="fullWidth" sx={{ width: "20rem", color: "#f46e3e", backgroundColor: "#f46e3e" }} /> */}
                    <IconButton
                        aria-label={paused ? 'pause' : 'play'}
                        sx={{ mx: 1, fontSize: "3rem", color: "#3a68cb" }}
                        onClick={setPaused}
                    >
                        {paused ? <PauseIcon fontSize="10rem" /> : <PlayIcon fontSize="10rem" />}
                    </IconButton>
                </Stack>
            </Card>


        </div>
    )
}

export default SubPlayer;
