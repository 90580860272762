import {
    Grid,
    Card as MCard,
    CardMedia,
    Stack,
    Typography,
    IconButton,
} from "@mui/material";
import PlayIcon from '@mui/icons-material/PlayCircleOutlineRounded';

const Card = ({ name, country, province, language, logo, url, favicon, onSelect }) => {
    return (
        <MCard
            variant="outlined"
            sx={{
                // width: "70vw",
                p: 2,
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'stretch',
                gap: 2,
            }}
        >

            <div style={{
                display: "flex", flexDirection: "row", gap: "2rem", flex: 1, justifyContent: "space-evenly"
            }}>
                <CardMedia
                    width="10%"
                    component="img"
                    height="100"
                    alt="Not Found"
                    src={logo || "https://images.pexels.com/photos/3783471/pexels-photo-3783471.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"}
                    sx={{
                        width: { xs: "2rem", sm: "2rem" },
                        height: "2rem",
                        borderRadius: 0.6,
                    }}
                />

                <Stack direction="column" spacing={1} alignItems="center"  width="80%" >
                    <Typography color="text.primary" fontWeight="medium" fontSize={12} >
                        {name}
                    </Typography>
                    <Typography
                        component="div"
                        variant="caption"
                        color="text.secondary"
                        fontWeight="regular"
                    >
                    {country && `${country}`} 
                    </Typography>

                    {/* <Typography
                        component="div"
                        variant="caption"
                        color="text.secondary"
                        fontWeight="regular"
                    >
                        
                    </Typography> */}
                </Stack>
                <IconButton
                 width="10%"
                    aria-label='pause'
                    sx={{ mx: 1, fontSize: "2rem", color: "#3a68cb" }}
                    onClick={() => onSelect({ name, language, province, country, url, favicon })}
                >
                    <PlayIcon fontSize="3rem" />
                </IconButton>
            </div>
        </MCard>
    );
}

export default Card;
