import Card from "./Card";
const StationList = ({ onSelect, stations }) => {
    return stations && stations.map((station) =>
        <>
            <Card
                language={station.language}
                country={station.country}
                province={station.state}
                logo={station.favicon}
                name={station.name}
                onSelect={() => onSelect({
                    name: station.name,
                    language: station.language,
                    state: station.state,
                    country: station.country,
                    url: station.url,
                    favicon: station.favicon
                })}
            />
        </>
    )
}

export default StationList;
