import {
    IconButton,
    TextField,
    Autocomplete,
    Button,
    Drawer,
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { useState } from 'react';

const LanguageList = [
    "tamil",
    "punjabi",
    "hindi",
    "english",
    "french",
    "malayalam",
    "telungu",
    "marathi"
];

const CountryList = [
    "India",
    "US",
    "Canada"
]; const FilterSection = ({ onClose, onSearch }) => {
    const [stationName, setStationName] = useState('');
    const [language, setLanguage] = useState('');
    const [country, setCountry] = useState('');
    const handleStationNameChange = (event) => {
        setStationName(event.target.value);
    };

    const handleLanguageChange = (value) => {
        console.log("handleLanguageChange", value);
        setLanguage(value);
    };

    const handleCountryChange = (value) => {
        console.log("handleCountryChange", value);
        setCountry(value);
    };

    const handleSearch = () => {
        console.log("handlese called")
        onSearch({ name: stationName, language, country });
    };
    return (
        <div
            role="presentation"
            style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                margin: "4rem",
                gap: "2rem",
            }}
            onClick={() => { }}
            onKeyDown={() => { }}
        >
            <div style={{ flexDirection: "row" }}>
                <IconButton
                    aria-label='pause'
                    sx={{ mx: 1, fontSize: "2rem", color: "#3a68cb" }}
                    onClick={() => onClose()}
                >
                    <ClearIcon fontSize="1rem" />
                </IconButton>
            </div>

            <TextField id="standard-basic" label="Station Name" variant="standard" onChange={handleStationNameChange} />
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={LanguageList}
                sx={{ width: 300 }}
                onChange={(event, newValue) => handleLanguageChange(newValue)}
                renderInput={(params) => <TextField {...params} label="Language" />}
            />
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={CountryList}
                sx={{ width: 300 }}
                onChange={(event, newValue) => handleCountryChange(newValue)}
                renderInput={(params) => <TextField {...params} label="Country"  />}
            />
            <Button variant="outlined"
                // color="#f46e3e"
                sx={{
                    color: "#3a68cb",
                    borderColor: "#3a68cb",
                    ":hover": {
                        borderColor: "#3a68cb",
                    }
                }
                }
                onClick={() => handleSearch()}
            >Search</Button>
        </div>
    )
}

export default FilterSection;
