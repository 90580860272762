import logo from './logo.svg';
import './App.css';
import ReactPlayer from 'react-player'
import { useState, useEffect } from 'react';
import Tile from "./Tile";
import MainPlayer from "./MainPlayer";

import StationList from './StationList';
import FilterIcon from '@mui/icons-material/FilterListRounded';
import ClearIcon from '@mui/icons-material/Clear';
import {
    IconButton,
    TextField,
    Autocomplete,
    Button,
    Drawer,
} from "@mui/material";
import FilterSection from './FilterSection';
import { favoriteStations } from "./DataSource";
import SubPlayer from './SubPlayer';
import { trackEvent } from './mixpanel';

function App() {
    const defaultUrl = "https://rogers-hls.leanstream.co/rogers/tor925.stream/playlist.m3u8";
    const defaultFavicon = "https://upload.wikimedia.org/wikipedia/en/thumb/7/7a/KiSS_92.5_Logo.png/150px-KiSS_92.5_Logo.png";

    const [pause, setPaused] = useState(false);
    const [screen, setScreen] = useState("startScreen");
    const [openFilters, setOpenFilters] = useState(false);

    const [name, setname] = useState(false);
    const [language, setLanguage] = useState("");
    const [country, setCountry] = useState("");
    const [province, setProvince] = useState("");
    const [url, setUrl] = useState(defaultUrl);
    const [favicon, setFavicon] = useState(defaultFavicon);

    const [searchName, setsearchName] = useState("");
    const [searchLanguage, setsearchLanguage] = useState("");
    const [searchCountry, setsearchCountry] = useState("");

    const [stations, setStations] = useState([]);

    useEffect(() => {
        fetchStations();
        trackEvent("Radio Player, User visited page", {});
    }, [searchName, searchLanguage, searchCountry]);

    const fetchStations = async () => {
        try {
            if (searchName || searchLanguage || searchCountry) {
                const url = `https://at1.api.radio-browser.info/json/stations/search?language=${searchLanguage}&name=${searchName}&country=${searchCountry}`;
                const response = await fetch(url, {
                    method: 'GET',
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                console.log({
                    data
                })
                setStations(data);
            } else {
                setStations(favoriteStations);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    }

    const Filter = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'flex-start', margin: "1rem" }}>
                <IconButton
                    aria-label='pause'
                    sx={{ mx: 1, fontSize: "2rem", color: "#3a68cb" }}
                    onClick={() => setOpenFilters(true)}
                >
                    <FilterIcon fontSize="1rem" />
                </IconButton>
            </div>
        )
    }

    const handleSearch = ({ name, language, country }) => {
        trackEvent("Radio Player, searching terms", { name, language, country });
        setsearchName(name);
        setsearchLanguage(language);
        setsearchCountry(country);

        setOpenFilters(false);
    }

    const handleOnStationSelect = ({ name, language, state, country, url, favicon }) => {
        trackEvent("Radio Player, playing terms", { url });
        setCountry(country);
        setLanguage(language);
        setname(name);
        setUrl(url);
        setFavicon(favicon);
        setProvince(state)
    }

    return (
        <div className="App">
            {/* <h1>Online Radio Stream</h1> */}
            {<Filter />}
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
                <div
                    style={{ display: 'none' }}
                >
                    <ReactPlayer
                        volume={1}
                        url={url}
                        playing={pause}
                        muted={false}
                        playsinline
                        style={{ background: "white", color: "white" }}
                    />
                </div>
                {/* <MainPlayer
                    paused={pause}
                    setPaused={() => setPaused(!pause)}
                    name={name}
                    state={province}
                    language={language}
                    country={country}
                    favicon={favicon}
                /> */}

                <div style={{ margin: "1rem"}}>

                </div>
                <stack xs={{
                    width: {xs: "90vw"}
                }}>
                <StationList
                        stations={stations}
                        onSelect={({ name, language, state, country, url, favicon }) => handleOnStationSelect({ name, language, state, country, url, favicon })}
                    />
                </stack>
                {/* <div style={{ width: "90vw"}}>
                    <StationList
                        stations={stations}
                        onSelect={({ name, language, state, country, url, favicon }) => handleOnStationSelect({ name, language, state, country, url, favicon })}
                    />
                </div> */}
            </div>
            <div style={{
                position: "fixed",
                bottom: 0,
            }}>
                <SubPlayer 
                    paused={pause}
                    setPaused={() => setPaused(!pause)}
                    name={name}
                    state={province}
                    language={language}
                    country={country}
                    favicon={favicon}
                />
            </div>
            <Drawer
                anchor="left"
                open={openFilters}
                onClose={() => setOpenFilters(false)}
            >
                <FilterSection
                    onSearch={({ name, language, country }) => handleSearch({ name, language, country })}
                    onClose={() => setOpenFilters(false)} />
            </Drawer>
        </div>
    );
}

export default App;
